<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        优惠券管理
      </template>
      <template #input>
        <a-select placeholder="优惠券状态" v-model="state" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">未开始</a-select-option>
          <a-select-option value="2">领取中</a-select-option>
          <a-select-option value="3">已结束</a-select-option>
        </a-select>
        <a-input placeholder="请输入关键字" v-model="name" allowClear/>
        <a-button type="primary" :loading="loading" @click="onSearch()" >搜索</a-button>
      </template>
    </HeaderBox>

    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="满减券" ></a-tab-pane>
        <!-- <a-tab-pane key="2" tab="兑换券" ></a-tab-pane> -->
        <a-tab-pane key="3" tab="折扣券" ></a-tab-pane>
      </a-tabs>
      <a-button class="all_boder_btn" @click="toDetail(1,userType)">新建</a-button>
    </div>

    <a-table
      class="table-template"
      :rowKey="(item) => item.couponId"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :scroll="{ x: 1500 }"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }" @change="onPage"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="couponPrice" slot-scope="item">
        {{item == null?'':item + '元'}}
      </template>
      <template slot="fullPrice" slot-scope="item">
        {{'满' + item + '元使用'}}
      </template>
      <template slot="discount" slot-scope="item">
        {{item + '折'}}
      </template>
      <template slot="discountPrice" slot-scope="item,row">
        {{'满' + item + (row.discountType == 1?'件':'元') + '使用'}}
      </template>
      <template slot="useTime" slot-scope="item">
        {{item?item:'不限'}}
      </template>
      <template slot="state" slot-scope="item">
        {{item==1?'未开始':item==2?'领取中':'已结束'}}
      </template>

      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="toDetail(4,userType,row.couponId)">领取记录</span> |
          <span v-if="row.state != 3" @click="overFilling(row.couponId)">结束 |</span>
          <span @click="toDetail(3,userType,row.couponId)"> 详情</span> |
          <span @click="onEdit(userType,row.couponId)">编辑</span> |
          <span @click="onDelete(row.couponId)">删除</span> 
          <!-- <span v-if="this.userType == 2" >发放兑换券</span> -->
          <!-- <span @click="exchangeFilling">发放兑换券</span> -->
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width='530px'
      title="编辑提示"
      :centered='true'
      :closable='false'
    >
      <div class="tips">当前创建优惠券已有用户领取，不可进行编辑。如需要其他面额的优惠券可新建。</div>
      <template slot="footer">
        <a-button type="primary" @click="editOk">确认</a-button>
      </template>
    </a-modal>

    <!-- <a-modal
      v-model="isDelete"
      width='530px'
      title="删除提示"
      :centered='true'
      :closable='false'
    >
      
      <div class="tips">{{noUpdate == 0?'当前创建优惠券已有用户领取，不可进行删除。如不想要更多用户领取该优惠券，可对其进行结束领取。':'是否确认删除该活动？'}}</div>
      <template slot="footer">
        <a-button type="primary" @click="deleteOk">确认</a-button>
      </template>
    </a-modal> -->

    <a-modal
      v-model="over"
      width='530px'
      title="停用提示"
      :centered='true'
      :closable='false'
    >
      <div class="tips">结束领取后优惠券将不能继续领取，但并不影响已领取的优惠券的使用。</div>
      <template slot="footer">
        <a-button type="primary" @click="overOk">确认</a-button>
        <a-button @click="overCancel">取消</a-button>
      </template>
    </a-modal>

    <!-- <a-modal
      v-model="exchange"
      width='530px'
      title="发放兑换券"
      @ok="exchangeOk"
      :centered='true'
      :closable='false'
    >
      <div>请选择想要发放的用户：</div>
      <div class="search-box">
        <a-input
          class="search-input"
          placeholder="请输入用户账号/昵称"
        />
        <a-button
          class="search-btn"
          type="primary"
        >添加</a-button>
      </div>
      <a-table
        class="table-template"
        rowKey="key"
        :columns="excolumns"
        :data-source="extableData"
        :pagination="{
        total: extotal,
        showTotal: (res) => `共${extotal}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
      >
        <template
          slot="index"
          slot-scope="item, row, index"
        >
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>
      </a-table>
      <div class="tips-box">注：确认发放后将会直接发放至用户账户中无法撤回，请再次确认。</div>
    </a-modal> -->

  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const reducecolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width: 80,
    fixed: "left",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "优惠券名称",
    align: "center",
    dataIndex: "name",
    width: 120,
    fixed: "left",
  },
  {
    title: "优惠券面额",
    align: "center",
    dataIndex: "couponPrice",
    width: 120,
    scopedSlots: {
      customRender: "couponPrice",
    },
  },
  {
    title: "使用门槛",
    align: "center",
    dataIndex: "fullPrice",
    width: 160,
    scopedSlots: {
      customRender: "fullPrice",
    },
  },
  // {
  //   title: "发放量",
  //   align: "center",
  //   dataIndex: "residue",
  //   width: 100,
  // },
  // {
  //   title: "剩余量",
  //   align: "center",
  //   dataIndex: "surplus",
  //   width: 100,
  // },
  {
    title: "优惠券使用时间",
    align: "center",
    dataIndex: "useTime",
    scopedSlots: {
      customRender: "useTime",
    },
    width: 340,
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
    width: 340,
  },
  {
    title: "领券时间",
    align: "center",
    dataIndex: "ledTime",
    width: 320,
  },
  {
    title: "领取状态",
    align: "center",
    dataIndex: "state",
    width: 100,
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "创建人员",
    align: "center",
    dataIndex: "createName",
    width: 100,
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    width: 260,
    fixed: "right",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
// const changecolumns = [
//   {
//     title: "序号",
//     align: "center",
//     dataIndex: "index",
//     width: 80,
//     fixed: "left",
//     scopedSlots: {
//       customRender: "index",
//     },
//   },
//   {
//     title: "兑换券名称",
//     align: "center",
//     dataIndex: "name",
//     width: 120,
//     fixed: "left",
//   },
//   {
//     title: "兑换时间",
//     align: "center",
//     dataIndex: "useTime",
//     width: 340,
//   },
//   {
//     title: "已发放",
//     align: "center",
//     dataIndex: "residue",
//     width: 100,
//   },
//   {
//     title: "创建时间",
//     align: "center",
//     dataIndex: "createTime",
//      width: 340,
//   },
//   {
//     title: "创建人员",
//     align: "center",
//     dataIndex: "createName",
//      width: 100,
//   },
//   {
//     title: "操作",
//     align: "center",
//     dataIndex: "operation",
//     width: 260,
//     fixed: "right",
//     scopedSlots: {
//       customRender: "operation",
//     },
//   },
// ];
const discountcolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width: 80,
    fixed: "left",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "折扣券名称",
    align: "center",
    dataIndex: "name",
    width: 120,
    fixed: "left",
  },
  {
    title: "折扣",
    align: "center",
    dataIndex: "discount",
     width: 100,
    scopedSlots: {
      customRender: "discount",
    },
  },
  {
    title: "使用门槛",
    align: "center",
    dataIndex: "discountPrice",
    width: 160,
    scopedSlots: {
      customRender: "discountPrice",
    },
  },
  // {
  //   title: "发放量",
  //   align: "center",
  //   dataIndex: "residue",
  //   width: 100,
  // },
  // {
  //   title: "剩余量",
  //   align: "center",
  //   dataIndex: "surplus",
  //   width: 100,
  // },
  {
    title: "优惠券使用时间",
    align: "center",
    dataIndex: "useTime",
    width: 340,
    scopedSlots: {
      customRender: "useTime",
    },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
    width: 340,
  },
  {
    title: "领券时间",
    align: "center",
    dataIndex: "ledTime",
    width: 340,
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "state",
    width: 100,
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    width: 260,
    fixed: "right",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
// const excolumns = [
//   {
//     title: "序号",
//     align: "center",
//     dataIndex: "index",
//     scopedSlots: {
//       customRender: "index",
//     },
//   },
//   {
//     title: "用户账号",
//     align: "center",
//     dataIndex: "account",
//   },
// ];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      name: '',
      state: undefined,
      loading:false,
      userType: 1,
      reducecolumns,
      // changecolumns,
      discountcolumns,
      columns: reducecolumns,
      tableData:[],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      noUpdate: 1,
      edit: false,
      // isDelete: false,
      over: false,
      isOver: '',
      // exchange: false,
      // excolumns,
      // extableData,
      // extotal: 0,
    };
  },
  // 事件处理器
  methods: {
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    callback(e) {
      this.userType = e;
    },
    toDetail(operation, userType, id) {
      if (operation == 4) {
        this.$router.push(
          "/admin/PromotionManage/receivingRecord?" + "userType=" + userType + "&id=" + id
        );
      } else {
        if (userType == 1) {
          this.$router.push(
            "/admin/PromotionManage/couponDetail?" + "operation=" + operation + "&id=" + id
          );
        } 
        // else if (userType == 2) {
        //   this.$router.push(
        //     "/admin/PromotionManage/exchangeDetail?" + "operation=" + operation + "&id=" + id
        //   );
        // } 
        else if (userType == 3) {
          this.$router.push(
            "/admin/PromotionManage/discountDetail?" + "operation=" + operation + "&id=" + id
          );
        }
      }
    },
    //编辑
    onEdit(u, e) {
      if (this.noUpdate == 0) {
        this.editFilling();
      } else {
        this.toDetail(2, u, e);
      }
    },
    editFilling() {
      this.edit = true;
    },
    editOk(e) {
      this.edit = false;
    },
    //结束
    overFilling(e) {
      this.over = true;
      this.isOver = e;
    },
    overCancel(e) {
      this.over = false;
    },
    overOk() {
      this.over = false;
      this.$ajax({
        url: "/hxclass-management/coupon-manage/manage/over/" + this.isOver,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //发放兑换券
    // exchangeFilling() {
    //   this.exchange = true;
    // },
    // exchangeCancel(e) {
    //   this.exchange = false;
    // },
    // exchangeOk(e) {
    //   this.exchange = false;
    // },
    getList(e) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/coupon-manage/manage/list",
        params: {
          name: this.name,
          state: this.state,
          type: this.userType,
          page: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    onDelete(e) {
      this.$confirm({
        title: "确定删除吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/coupon-manage/manage/delete/" + e,
            method: "delete",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    userType(newval) {
      this.getList();
      if (newval == 1) {
        this.columns = reducecolumns;
      } 
      // else if (newval == 2) {
      //   this.columns = changecolumns;
      // } 
      else if (newval == 3) {
        this.columns = discountcolumns;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  .table-box {
    display: flex;
    justify-content: space-between;

    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
.tips {
  font-size: 16px;
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-title {
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
.search-box {
  margin: 10px 0;
  .search-input {
    width: 76%;
    margin-right: 24px;
    margin-bottom: 6px;
  }
}
.tips-box {
  font-size: 14px;
  color: #666;
}
</style>
